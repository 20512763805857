import type {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	HeadersDefaults,
	ResponseType,
} from "axios";
import axios from "axios";

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface PaginatedActivityLogViewModel {
	data: (
		| ActivityLogViewModel
		| CallAttemptActivityLogViewModel
		| NotificationReceivedActivityLogViewModel
	)[];
}

export interface CoachMembersCountViewModel {
	coachingGroup30: number;
	coachingGroup70: number;
	total: number;
}

export interface CoachViewModel {
	id: number;
	givenName: string;
	familyName: string;
	gymIds: number[];
	imageUrl: string | null;
	membersCount: CoachMembersCountViewModel;
	hasLogin: boolean;
}

export interface PaginatedCoachViewModel {
	data: CoachViewModel[];
}

export interface MemberPersonalDetailsParams {
	sex: "m" | "f";
	/** @default "nld" */
	language: "nld" | "eng";
	firstName: string;
	lastName: string;
	address?: string | null;
	email: string;
	dateOfBirth?: string | null;
	location?: string | null;
	postalCode?: string | null;
	phone?: string | null;
	targetFrequency?: number | null;
	phoneMobile?: string | null;
	gymId: number;
}

export interface MemberViewModel {
	id: number;
	email: string;
	language: string;
	postalCode: string | null;
	dateOfBirth: string | null;
	address: string | null;
	location: string | null;
}

export interface MemberCoachViewModel {
	coachId: number | null;
	memberId: number | null;
	coachingsGroep: string | null;
}

export interface NotificationPreferences {
	memberID: number;
	/** @format date-time */
	pausedUntil?: string | null;
	reservations: object[];
	cancellations: object[];
	lessons: object[];
	memberActions: object[];
	compliments: object[];
	friends: object[];
	reports: object[];
	generalInformation: object[];
	encouragements: object[];
}

export interface NotificationPreferencesUpdateInput {
	/** @format date-time */
	pausedUntil?: string | null;
}

export interface ScheduledNotificationViewModel {
	id: number;
	/** @format date-time */
	scheduledOn: string;
	title: string;
	content: string;
	language: object;
	deleted: boolean;
}

export interface PaginatedScheduledNotificationViewModel {
	data: ScheduledNotificationViewModel[];
}

export interface CreateAppointmentSlotArgs {
	day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
	gymId: number;
	roomId: number;
	groupLessonId?: number | null;
	/** @pattern /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/ */
	time: string;
	/** @min 1 */
	duration: number;
	itemType: string;
	startWeek?: number;
	endWeek?: number;
}

export interface CreateAppointmentSlotsArgs {
	slots: CreateAppointmentSlotArgs[];
}

export interface AppointmentSlotViewModel {
	id: number;
	gymId: number;
	day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
	groupLessonId: number | null;
	coachId: number;
	scheduleId: number;
	roomId: number;
	slots: number;
	time: string;
	duration: number;
	startWeek: number | null;
	endWeek: number | null;
	previousItemId: number | null;
	covidSlot: number | null;
	itemType: string | null;
}

export interface UpdateAppointmentSlotArgs {
	day?: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";
	gymId?: number;
	roomId?: number;
	groupLessonId?: number | null;
	/** @pattern /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/ */
	time?: string;
	/** @min 1 */
	duration?: number;
	itemType?: string;
	startWeek?: number;
	endWeek?: number;
}

export interface LessonWaitingListViewModel {
	memberId: number;
	lessonId: number;
	/** @format date-time */
	signedUpAt: string | null;
}

export interface LessonHasAvailableSpotsExceptionDto {
	/**
	 * @example
	 * 	400;
	 */
	status: number;
	/**
	 * @example
	 * 	Bad Request
	 */
	message: string;
	/**
	 * @example
	 * 	The lesson still has available spots
	 */
	error: string;
	/**
	 * @example
	 * 	LESSON_HAS_AVAILABLE_SPOTS;
	 */
	errorCode: string;
}

export interface PaginatedLessonWaitingListViewModel {
	data: LessonWaitingListViewModel[];
}

export interface RegisterMemberArgs {
	memberId: number;
	recurring: boolean;
}

export interface LessonViewModel {
	id: number;
	week: number;
	/** @format date-time */
	date: string;
	name: string;
	totalSpots: number;
	occupiedSpots: number;
	status: "geannuleerd" | "aankomend" | "afgelopen";
}

export interface ActivityLogViewModel {
	type: "ASSESSMENT" | "CHECKIN";
	/** @format date-time */
	timestamp: string;
}

export interface CallAttemptActivityLogViewModel {
	type: "CALL_ATTEMPT";
	didMemberPickUp: boolean;
	comments?: string;
	/** @format date-time */
	timestamp: string;
}

export interface NotificationReceivedActivityLogViewModel {
	type: "NOTIFICATION_RECEIVED";
	notificationType?:
		| "encouragement-gym-visit"
		| "les-reservering"
		| "les-reservering-annulering"
		| "afspraak-reservering"
		| "tijdslot-reservering"
		| "les-gemist"
		| "afspraak-gemist"
		| "tijdslot-gemist"
		| "les-gewijzigd"
		| "tijdslot-gewijzigd"
		| "les-geannuleerd"
		| "tijdslot-geannuleerd"
		| "afspraak-geannuleerd"
		| "herhaling-afgemeld"
		| "schema-6-weken-oud"
		| "meting-4-weken-oud"
		| "geen-doel-opgesteld"
		| "les-bijna-vol"
		| "compliment-aantal-bezoeken"
		| "compliment-aantal-groepslessen"
		| "compliment-over-meting"
		| "vriend-al-geweest"
		| "vriend-aansporen"
		| "maand-rapport"
		| "kwartaal-rapport"
		| "jaar-rapport"
		| "specifieke-datum-notificatie"
		| "afval-tips"
		| "spierballen-arrangement"
		| "vaste-sportdag"
		| "vaker-fitness-afspraak"
		| "zonnebank-tip"
		| "verjaardag"
		| "lang-niet-geweest"
		| "vriend-les-uitnodiging"
		| "vriend-uitnoding"
		| "les-herinnering"
		| "arr-afgesloten-lid"
		| "arr-annuleren-lid";
	/** @format date-time */
	timestamp: string;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
	extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
	/** Set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** Request path */
	path: string;
	/** Content type of request body */
	type?: ContentType;
	/** Query params */
	query?: QueryParamsType;
	/** Format of response (i.e. response.json() -> format: "json") */
	format?: ResponseType;
	/** Request body */
	body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown>
	extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
	secure?: boolean;
	format?: ResponseType;
}

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public instance: AxiosInstance;
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private secure?: boolean;
	private format?: ResponseType;

	constructor({
		securityWorker,
		secure,
		format,
		...axiosConfig
	}: ApiConfig<SecurityDataType> = {}) {
		this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
		this.secure = secure;
		this.format = format;
		this.securityWorker = securityWorker;
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected mergeRequestParams(
		params1: AxiosRequestConfig,
		params2?: AxiosRequestConfig,
	): AxiosRequestConfig {
		const method = params1.method || (params2 && params2.method);

		return {
			...this.instance.defaults,
			...params1,
			...(params2 || {}),
			headers: {
				...((method &&
					this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
					{}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected stringifyFormItem(formItem: unknown) {
		if (typeof formItem === "object" && formItem !== null) {
			return JSON.stringify(formItem);
		} else {
			return `${formItem}`;
		}
	}

	protected createFormData(input: Record<string, unknown>): FormData {
		if (input instanceof FormData) {
			return input;
		}
		return Object.keys(input || {}).reduce((formData, key) => {
			const property = input[key];
			const propertyContent: any[] = property instanceof Array ? property : [property];

			for (const formItem of propertyContent) {
				const isFileType = formItem instanceof Blob || formItem instanceof File;
				formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
			}

			return formData;
		}, new FormData());
	}

	public request = async <T = any, _E = any>({
		secure,
		path,
		type,
		query,
		format,
		body,
		...params
	}: FullRequestParams): Promise<AxiosResponse<T>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const responseFormat = format || this.format || undefined;

		if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
			body = this.createFormData(body as Record<string, unknown>);
		}

		if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
			body = JSON.stringify(body);
		}

		return this.instance.request({
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type ? { "Content-Type": type } : {}),
			},
			params: query,
			responseType: responseFormat,
			data: body,
			url: path,
		});
	};
}

/**
 * @version 0.0.2
 * @title ProFit Gym core backend
 * @contact
 */
export class CoreApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	/**
	 * No description
	 *
	 * @name AppControllerRoot
	 * @request GET:/
	 */
	appControllerRoot = (params: RequestParams = {}) =>
		this.request<void, any>({
			path: `/`,
			method: "GET",
			...params,
		});

	members = {
		/**
		 * No description
		 *
		 * @name MemberActivityLogControllerGetActivityLogs
		 * @request GET:/members/{memberId}/activity-logs
		 */
		memberActivityLogControllerGetActivityLogs: (
			memberId: number,
			query: {
				/** @format date-time */
				"where[timestamp][gte]": string;
				/** @format date-time */
				"where[timestamp][lt]": string;
				type?: "ASSESSMENT" | "CALL_ATTEMPT" | "CHECKIN" | "NOTIFICATION_RECEIVED";
			},
			params: RequestParams = {},
		) =>
			this.request<PaginatedActivityLogViewModel, any>({
				path: `/members/${memberId}/activity-logs`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Members
		 * @name MemberControllerUpdate
		 * @request PATCH:/members/{memberId}/personal-details
		 */
		memberControllerUpdate: (
			memberId: number,
			data: MemberPersonalDetailsParams,
			params: RequestParams = {},
		) =>
			this.request<MemberViewModel, any>({
				path: `/members/${memberId}/personal-details`,
				method: "PATCH",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Members
		 * @name MemberControllerUpdateCoach
		 * @request PUT:/members/{memberId}/personal-details/coach
		 */
		memberControllerUpdateCoach: (
			memberId: number,
			query?: {
				/** @default null */
				coachId?: number | null;
			},
			params: RequestParams = {},
		) =>
			this.request<MemberCoachViewModel, any>({
				path: `/members/${memberId}/personal-details/coach`,
				method: "PUT",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Members: notification
		 * @name MemberNotificationPreferencesControllerGetNotificationPreferences
		 * @request GET:/members/{memberId}/notification-preferences
		 */
		memberNotificationPreferencesControllerGetNotificationPreferences: (
			memberId: number,
			params: RequestParams = {},
		) =>
			this.request<NotificationPreferences, any>({
				path: `/members/${memberId}/notification-preferences`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Members: notification
		 * @name MemberNotificationPreferencesControllerUpdateNotificationPreferences
		 * @request PATCH:/members/{memberId}/notification-preferences
		 */
		memberNotificationPreferencesControllerUpdateNotificationPreferences: (
			memberId: number,
			data: NotificationPreferencesUpdateInput,
			params: RequestParams = {},
		) =>
			this.request<NotificationPreferences, any>({
				path: `/members/${memberId}/notification-preferences`,
				method: "PATCH",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @name MemberNotificationControllerGetScheduledNotifications
		 * @request GET:/members/{memberId}/scheduled-notifications
		 */
		memberNotificationControllerGetScheduledNotifications: (
			memberId: string,
			params: RequestParams = {},
		) =>
			this.request<PaginatedScheduledNotificationViewModel, any>({
				path: `/members/${memberId}/scheduled-notifications`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Waiting list
		 * @name LessonWaitingListControllerGetByMember
		 * @request GET:/members/{memberId}/lessons/waiting-list
		 */
		lessonWaitingListControllerGetByMember: (memberId: number, params: RequestParams = {}) =>
			this.request<PaginatedLessonWaitingListViewModel, any>({
				path: `/members/${memberId}/lessons/waiting-list`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	coaches = {
		/**
		 * No description
		 *
		 * @tags Coaches
		 * @name CoachControllerFindAll
		 * @request GET:/coaches
		 */
		coachControllerFindAll: (
			query?: {
				gymId?: number;
				/** @default false */
				onlyWithLogin?: boolean;
			},
			params: RequestParams = {},
		) =>
			this.request<PaginatedCoachViewModel, any>({
				path: `/coaches`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	appointmentSlots = {
		/**
		 * No description
		 *
		 * @tags appointment-slots
		 * @name AppointmentSlotsControllerAdd
		 * @request POST:/appointment-slots
		 */
		appointmentSlotsControllerAdd: (data: CreateAppointmentSlotsArgs, params: RequestParams = {}) =>
			this.request<AppointmentSlotViewModel[], any>({
				path: `/appointment-slots`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags appointment-slots
		 * @name AppointmentSlotsControllerDelete
		 * @request DELETE:/appointment-slots
		 */
		appointmentSlotsControllerDelete: (data: string[], params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/appointment-slots`,
				method: "DELETE",
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags appointment-slots
		 * @name AppointmentSlotsControllerUpdate
		 * @request PATCH:/appointment-slots/{id}
		 */
		appointmentSlotsControllerUpdate: (
			id: number,
			data: UpdateAppointmentSlotArgs,
			params: RequestParams = {},
		) =>
			this.request<AppointmentSlotViewModel, any>({
				path: `/appointment-slots/${id}`,
				method: "PATCH",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	schedule = {
		/**
		 * No description
		 *
		 * @tags Schedule
		 * @name ScheduleControllerGetBySchedule
		 * @request GET:/schedule/{scheduleId}/appointment-slots
		 */
		scheduleControllerGetBySchedule: (
			scheduleId: number,
			query?: {
				gymId?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<AppointmentSlotViewModel[], any>({
				path: `/schedule/${scheduleId}/appointment-slots`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	lessons = {
		/**
		 * No description
		 *
		 * @tags Waiting list
		 * @name LessonWaitingListControllerAdd
		 * @request POST:/lessons/{lessonId}/members/{memberId}/waiting-list
		 */
		lessonWaitingListControllerAdd: (
			memberId: number,
			lessonId: number,
			params: RequestParams = {},
		) =>
			this.request<LessonWaitingListViewModel, LessonHasAvailableSpotsExceptionDto>({
				path: `/lessons/${lessonId}/members/${memberId}/waiting-list`,
				method: "POST",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Waiting list
		 * @name LessonWaitingListControllerDelete
		 * @request DELETE:/lessons/{lessonId}/members/{memberId}/waiting-list
		 */
		lessonWaitingListControllerDelete: (
			memberId: number,
			lessonId: number,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/lessons/${lessonId}/members/${memberId}/waiting-list`,
				method: "DELETE",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lessons
		 * @name LessonControllerSignup
		 * @request POST:/lessons/{lessonId}/signup
		 */
		lessonControllerSignup: (
			lessonId: number,
			data: RegisterMemberArgs,
			params: RequestParams = {},
		) =>
			this.request<LessonViewModel, any>({
				path: `/lessons/${lessonId}/signup`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Lessons
		 * @name LessonControllerSignOff
		 * @request POST:/lessons/{lessonId}/signoff
		 */
		lessonControllerSignOff: (
			lessonId: number,
			data: RegisterMemberArgs,
			params: RequestParams = {},
		) =>
			this.request<LessonViewModel, any>({
				path: `/lessons/${lessonId}/signoff`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
}
