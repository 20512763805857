



























































































import { type PropType, computed, defineComponent, ref } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { clubApi, coreApi } from "@/lib/backend";
import type { CoachViewModel } from "@/lib/backend/core.api";
import { formatErrorMessage } from "@/lib/formatErrorMessage";
import { getFullName } from "@/lib/getFullName";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";
import { toast } from "@/lib/vue2-sonner";

export default defineComponent({
	props: {
		data: {
			type: Object as PropType<{
				memberIds: number[];
				gymId?: number;
				callback?: VoidFunction;
			}>,
			required: true,
		},
	},
	emits: {
		close() {
			return true;
		},
	},
	setup(props, { emit }) {
		const isOpen = ref(false);
		const selectedCoach = ref<CoachViewModel | null>(null);

		const { id: currentGymId } = useCurrentGym();
		const gymId = computed(() => props.data.gymId ?? currentGymId.value);

		const queryKey = computed(() => ["coaches", gymId.value] as const);

		const { data: coaches } = useQuery({
			queryKey,
			queryFn: async (context) =>
				await coreApi.coaches
					.coachControllerFindAll({ gymId: context.queryKey[1] }, { signal: context.signal })
					.then((response) => response.data.data),
			select: (data) => data.sort((a, b) => getFullName(a).localeCompare(getFullName(b))),
			initialData: [] as Awaited<
				ReturnType<typeof coreApi.coaches.coachControllerFindAll>
			>["data"]["data"],
		});

		async function submit() {
			if (!selectedCoach.value) {
				throw new Error("No coach selected");
			}

			await toast
				.promise(
					clubApi.coach.ledenToewijzen(selectedCoach.value.id, { ledenIds: props.data.memberIds }),
					{
						loading: props.data.memberIds.length === 1 ? "Lid toewijzen..." : "Leden toewijzen...",
						success: props.data.memberIds.length === 1 ? "Lid toegewezen" : "Leden toegewezen",
						error: formatErrorMessage,
						finally: () => {
							props.data.callback?.();
							emit("close");
						},
					},
				)
				?.unwrap();
		}

		return { getFullName, isOpen, selectedCoach, coaches, submit };
	},
});
