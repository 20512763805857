import { AxiosError } from "axios";

export function formatErrorMessage(error: unknown): string {
	if (error instanceof AxiosError) {
		if (error.response?.data.error.message) {
			return error.response.data.error.message;
		}
	}

	if (error instanceof Error) {
		return error.message;
	}

	return JSON.stringify(error);
}
