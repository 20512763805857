









































































































import { type PropType, defineComponent } from "@vue/composition-api";
import { mapActions } from "vuex";
import { dagMaandJaar } from "@/utils/date";

export default defineComponent({
	name: "ModalGroepslesAfmelden",
	props: {
		data: {
			type: Object as PropType<{
				les: {
					les_id: number;
					groepsles_naam: string;
					date: string;
					tijd: string;
					vestiging_naam: string;
					herhalend: boolean;
				};
				lid: { lidId: number; lidNaam: string };
				lidId: number;
			}>,
			default: () => ({}),
		},
		callback: {
			type: Function as PropType<() => void | Promise<void>>,
			default: () => {
				return;
			},
		},
	},
	data() {
		return {
			message: "",
			error: false,
			herhalend: false,
		};
	},
	methods: {
		dagMaandJaar,
		...mapActions("tijdsloten", ["tijdslotAfmeldenLid"]),
		afmelden() {
			this.tijdslotAfmeldenLid({
				lesId: +this.data.les.les_id,
				lidId: (this.data.lid && +this.data.lid.lidId) || +this.data.lidId,
				herhalend: this.herhalend,
			})
				.then(async (data) => {
					if (data.error) {
						this.error = true;
						this.message = `Er is iets mis gegaan: ${data.error}`;
						return false;
					}
					this.message = "Je hebt het lid succesvol afgemeld.";
					await this.callback();
					return false;
				})
				.catch((err) => {
					this.error = true;
					this.message = `Er is iets mis gegaan: ${err}`;
				});
		},
	},
});
