/* https://decipher.dev/30-seconds-of-typescript/docs/debounce */

export function debounce(
	fn: (...args: unknown[]) => void,
	ms = 300,
): (this: unknown, ...args: unknown[]) => void {
	let timeoutId: ReturnType<typeof setTimeout>;
	return function (this: unknown, ...args: unknown[]) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => fn.apply(this, args), ms);
	};
}
