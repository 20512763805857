import { useQuery } from "@tanstack/vue-query";
import { reserveerApi } from "@/lib/backend";

export function useGyms() {
	return useQuery({
		queryKey: ["gyms"] as const,
		queryFn: async (context) =>
			await reserveerApi.vestigingen
				.vestigingen({ signal: context.signal })
				.then((response) => response.data),
		select: (data) => data.sort((a, b) => a.naam.localeCompare(b.naam)),
		placeholderData: [] as Awaited<ReturnType<typeof reserveerApi.vestigingen.vestigingen>>["data"],
		cacheTime: Infinity,
		staleTime: Infinity,
	});
}
