import { computed, watch } from "@vue/composition-api";
import { StorageSerializers, useStorage } from "@vueuse/core";
import { performForAllApis } from "@/lib/backend";
import { LS_LOCATION } from "@/lib/constants/localstorage";
import { DEFAULT_LOCATION, DEFAULT_LOCATION_ID } from "@/lib/constants/misc";
import { useGyms } from "@/lib/query/hooks/useGyms";

export function useCurrentGym() {
	const { data: gyms } = useGyms();

	/** @deprecated Legacy, please use `gymId` as source of truth */
	const locationSlug = useStorage<string>(LS_LOCATION, DEFAULT_LOCATION, localStorage, {
		serializer: StorageSerializers.string,
	});

	const gymId = useStorage<number>(
		"gymId",
		gyms.value?.find((gym) => gym.slug === locationSlug.value)?.id ??
			gyms.value?.[0]?.id ??
			DEFAULT_LOCATION_ID,
		localStorage,
		{
			serializer: StorageSerializers.number,
		},
	);

	const gym = computed(() => gyms.value?.find((gym) => gym.id === gymId.value));
	const gymSlug = computed(() => gym.value?.slug);

	watch(
		[gyms, gym],
		([currentGyms, currentGym]) => {
			if (!currentGyms || currentGyms.length === 0 || currentGym) {
				return;
			}

			gymId.value = currentGyms[0].id;
		},
		{ immediate: true },
	);

	watch(
		gymSlug,
		(currentGymSlug) => {
			if (!currentGymSlug) {
				return;
			}

			localStorage.setItem(LS_LOCATION, currentGymSlug);
			performForAllApis(
				(api) => (api.instance.defaults.headers.common["Gym-Location"] = currentGymSlug),
			);
		},
		{ immediate: true },
	);

	return {
		/** @deprecated Please use dynamic `slug` instead */
		legacy_locationSlug: locationSlug,
		id: gymId,
		slug: gymSlug,
		gym,
	};
}
