



















































































import { defineComponent } from "@vue/composition-api";
import {
	mapActions as mapPiniaActions,
	mapState as mapPiniaState,
	mapWritableState as mapPiniaWritableState,
} from "pinia";
import { mapActions } from "vuex";
import { logger } from "@/logger";
import { useLocationStore } from "@/pinia/location";
import { useMemberStore } from "@/pinia/member";

type Data = {
	loading: boolean;
	message?: string;
	error: boolean;
	vestiging?: string;
	gebruikerId?: number;
};

export default defineComponent({
	name: "PfgModalAfspraakInplannen",
	data(): Data {
		return {
			loading: false,
			message: undefined,
			error: false,
			vestiging: undefined,
			gebruikerId: undefined,
		};
	},
	computed: {
		...mapPiniaState(useMemberStore, {
			members: "members",
			membersLoading: ({ state: { loading } }) => loading,
		}),
		...mapPiniaState(useLocationStore, {
			location: "location",
			locations: "locationsSortedByName",
		}),
		...mapPiniaWritableState(useMemberStore, {
			filters: "filters",
		}),
	},
	watch: {
		filters: {
			async handler() {
				await this.emptyMembers();
				await this.resetMembersState();
				await this.getMembersDebounced();
			},
			deep: true,
		},

		location: {
			async handler(newLocation) {
				logger.log("location changed", newLocation);
				this.vestiging = newLocation?.naam;
			},
			immediate: true,
		},

		async vestiging(newValue: string) {
			await this.setFilters({ vestiging: newValue });
		},
	},
	async created() {
		await this.check();
	},
	async destroyed() {
		await this.resetFilters();
	},

	methods: {
		...mapPiniaActions(useMemberStore, {
			initMembers: "init",
			getMembers: "get",
			emptyMembers: "empty",
			getMembersDebounced: "debouncedGet",
			setFilters: "setFilters",
			resetFilters: "resetFilters",
			setMembersState: "setState",
			resetMembersState: "resetState",
		}),
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapActions("modal", { openModal: "openModal" }),
		async check() {
			this.loading = true;

			await this.initLocation();
			this.vestiging = this.location?.naam;

			await this.initMembers();

			await this.resetMembersState();
			await this.setFilters({
				search: "",
				vestiging: this.vestiging,
			});
			await this.getMembers();

			this.loading = false;
		},
		naarLid() {
			this.$emit("close");
			this.$router.push({
				name: "Gebruiker afspraak inplannen",
				params: { id: String(this.gebruikerId) },
			});
		},
		openTempMemberModal() {
			this.openModal({
				name: "temp-member",
				data: {
					popup: {
						enable: true,
						title: "Het lid is succesvol aangemaakt",
						body: "Maak eventueel een afspraak met het lid",
						cancelText: "Sluiten",
						actionText: "Maak afspraak",
					},
				},
				callback: ({ id }: { id: number }) => {
					this.$router.push({
						name: "Gebruiker afspraak inplannen",
						params: { id: String(id) },
					});
				},
			});
		},
	},
});
