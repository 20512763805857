

































































































































































































import { type PropType, defineComponent } from "@vue/composition-api";
import {
	mapActions as mapPiniaActions,
	mapWritableState as mapPiniaWritableState,
	mapState,
} from "pinia";
import { mapActions } from "vuex";
import type { LesUitgebreid } from "@/lib/backend/coach.api";
import { useAppStore } from "@/pinia/app";
import { useLocationStore } from "@/pinia/location";
import { useMemberStore } from "@/pinia/member";
import { getDateFromDatum, getDayMonthYear } from "@/utils/date";

type Data = {
	herhalend: boolean;
	message: string;
	error: boolean;
	lidId?: number;
};

export default defineComponent({
	props: {
		data: {
			type: Object as PropType<{
				search: string;
				les: LesUitgebreid;
				lid: { lidId: number; id: number };
			}>,
			default: () => ({}),
		},
		callback: {
			type: Function as PropType<() => void | Promise<void>>,
			default: () => {
				return;
			},
		},
	},
	data(): Data {
		return {
			herhalend: false,
			message: "",
			error: false,
			lidId: undefined,
		};
	},
	computed: {
		...mapState(useMemberStore, {
			members: "members",
			membersLoading: ({ state: { loading } }) => loading,
		}),
		...mapState(useLocationStore, {
			location: "location",
			locations: "locationsSortedByName",
		}),
		...mapPiniaWritableState(useMemberStore, ["filters"]),
		...mapState(useAppStore, ["occupancyFormat"]),
	},
	watch: {
		filters: {
			async handler() {
				await this.emptyMembers();
				await this.resetMembersState();
				await this.getMembersDebounced();
			},
			deep: true,
		},
	},
	async created() {
		await this.initMembers();
		await this.initLocation();

		await this.resetMembersState();
		await this.setFilters({
			search: "",
			vestiging: this.location?.slug ?? "all",
		});
		await this.getMembers();
	},
	async destroyed() {
		await this.resetFilters();
	},
	mounted() {
		if (this.data && this.data.lid && this.data.lid.lidId) {
			this.lidId = +this.data.lid.lidId;
		} else if (this.data && this.data.lid && this.data.lid.id) {
			this.lidId = +this.data.lid.id;
		}
	},
	methods: {
		...mapPiniaActions(useMemberStore, {
			initMembers: "init",
			getMembers: "get",
			emptyMembers: "empty",
			getMembersDebounced: "debouncedGet",
			setFilters: "setFilters",
			resetFilters: "resetFilters",
			setMembersState: "setState",
			resetMembersState: "resetState",
		}),
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapActions("groepsles", { lesReserverenLid: "lesReserverenLid" }),
		...mapActions("modal", { openModal: "openModal" }),
		async aanmelden() {
			const isValid = await this.$validator.validateAll();

			if (isValid) {
				const query = this.data.search
					? {
							lesId: +this.data.les.les_id,
							lijst: [
								{
									lidId: this.lidId,
									herhalend: false,
									status: "aangemeld",
								},
							],
						}
					: {
							lesId: +this.data.les.les_id,
							lijst: [
								{
									lidId: this.lidId,
									herhalend: this.herhalend,
									status: "aangemeld",
								},
							],
						};

				try {
					await this.lesReserverenLid(query);

					this.message = "Je hebt het lid succesvol aangemeld.";
					await this.callback();
				} catch (error) {
					this.error = true;
					this.message = error as string;
				}
			}
		},
		openTempMemberModal() {
			const { openModal, data } = this;

			this.openModal({
				name: "temp-member",
				data: {
					popup: {
						enable: false,
					},
				},
				callback: async (closeModal: () => void, user: { id: number }) => {
					closeModal();
					openModal({
						name: "groepsles-aanmelden",
						data: {
							...data,
							lid: user,
							search: false,
						},
						callback: this.callback
							? this.callback
							: () => {
									return;
								},
					});
				},
			});
		},
		getDateFromDatum,
		getDayMonthYear,
	},
});
