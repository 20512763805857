import { config } from "@/lib/config";
import service from "@/lib/service";

/** @deprecated Please use `pinia` instead */
const groepsles = {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		getLes(_, id) {
			return service.get(`lessen/${id}`);
		},
		getLessenActueel() {
			return service.get("lessen/actueel");
		},
		getAanwezigheidsLijst(_, id) {
			return service.get(`lessen/${id}/aanwezigheidslijst`);
		},
		postAanwezigheidsLijst(_, data) {
			return service.post("lessen/aanwezigheidslijst", data);
		},
		postAanwezigheidsLijstDefinitief(_, lesId) {
			return service.post(`lessen/${lesId}/aanwezigheidslijst/definitief`);
		},
		// api/lessen/aanwezigheidslijst
		// Wijzig de aanwezigheidslijst voor een les. Use cases:
		// (1) registreren wie aan/afwezig was bij de les
		// (2) reserveren van een enkele les (evt herhalend)
		lesReserverenLid(_, data) {
			return service.post("lessen/aanwezigheidslijst", data);
		},
		lesAfmeldenLid(_, { lesId, lidId, herhalend }) {
			const data = {
				lesId,
				lijst: [
					{
						lidId,
						status: "afgemeld",
						herhalend,
					},
				],
			};
			return service.post("lessen/aanwezigheidslijst", data);
		},
		lesAnnuleren(_, payload) {
			return service.post("lessen/annuleren", payload);
		},
		undoCancellation(_, lesId) {
			return service.delete(`${config.reserveer.server}/lessen/annuleren/${lesId}`);
		},
		sendMessageToParticipants(_, { lesId, title, message }) {
			return service.post(`${config.reserveer.server}/lessen/bericht`, {
				id: lesId,
				titel: title,
				inhoud: message,
				geplandOp: new Date(),
			});
		},
	},
};

export default groepsles;
