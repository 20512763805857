import { type ComputedRef, computed } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { minutesToMilliseconds } from "date-fns";
import { coreApi } from "@/lib/backend";
import { getFullName } from "@/lib/getFullName";

export function useCoaches(
	query?: ComputedRef<Parameters<typeof coreApi.coaches.coachControllerFindAll>[0]>,
) {
	const queryKey = computed(
		() => ["coaches", query?.value?.gymId, query?.value?.onlyWithLogin] as const,
	);

	return useQuery({
		queryKey,
		queryFn: async (context) =>
			await coreApi.coaches
				.coachControllerFindAll(
					{
						gymId: context.queryKey[1],
						onlyWithLogin: context.queryKey[2],
					},
					{ signal: context.signal },
				)
				.then((response) => response.data.data),
		select: (data) => data.sort((a, b) => getFullName(a).localeCompare(getFullName(b))),
		placeholderData: [] as Awaited<
			ReturnType<typeof coreApi.coaches.coachControllerFindAll>
		>["data"]["data"],
		cacheTime: minutesToMilliseconds(30),
		staleTime: minutesToMilliseconds(5),
	});
}
