import service from "@/lib/service";

/** @deprecated Please use `pinia` instead */
const tijdslot = {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		getFitnessBlok(_, id) {
			return service.get(`tijdsloten/${id}`);
		},
		getTijdslotenActueel() {
			return service.get("tijdsloten/actueel");
		},
		getAanwezigheidsLijst(_, id) {
			return service.get(`tijdsloten/${id}/aanwezigheidslijst`);
		},
		postAanwezigheidsLijst(_, data) {
			return service.post("tijdsloten/aanwezigheidslijst", data);
		},
		postAanwezigheidsLijstDefinitief(_, lesId) {
			return service.post(`tijdsloten/${lesId}/aanwezigheidslijst/definitief`);
		},
		// api/tijdsloten/aanwezigheidslijst
		// Wijzig de aanwezigheidslijst voor een les. Use cases:
		// (1) registreren wie aan/afwezig was bij de les
		// (2) reserveren van een enkele les (evt herhalend)
		tijdslotReserverenLid(_, data) {
			return service.post("tijdsloten/aanwezigheidslijst", data);
		},
		tijdslotAfmeldenLid(_, { lesId, lidId, herhalend }) {
			const data = {
				lesId,
				lijst: [
					{
						lidId,
						status: "afgemeld",
						herhalend,
					},
				],
			};
			return service.post("tijdsloten/aanwezigheidslijst", data);
		},
	},
};

export default tijdslot;
